.icons {
    text-align: center;
    padding: 10px;
    width: 100%;
}

.socialIcon {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 5px 0 0;
}