.donate-button {
    width: 100%;
    text-align: center;
}

.button {
    width: 30px;
    height: 30px;
}

