.sectionHeading {
    margin-bottom: 20px !important;
}

.criticalQuestionItem {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row;

}

.criticalQuestionsIndicator {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 5px;
    flex: 0 0 auto;
}

.criticalQuestionLink {
    text-decoration: none;
    color: black;
}

.scoreEmoji {
    padding: 5px;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

.categoryOverviewText {
    text-align: center;
    padding: 5px;
    min-height: 50px;
    text-transform: capitalize;
}


.categoryDetailsHeading {
    margin-top: 80px !important;
    margin-bottom: 20px !important;
    text-transform: capitalize;
}

.categoryOverviewPercentage {
    padding-top: 5px;
    text-align: center;
    color: rgba(144, 98, 202, 0.6);
}

.questionDetailsGridCell {
    padding: 4px 24px 4px 0 !important;
}