.footer {
    margin-top: auto;
}

.footer_nav {
    text-align: center;
}

.footer_nav_list {
    padding-inline-start: 0px;
}

.footer_nav_list li {

    display: inline;
    list-style-type: none;
    padding: 10px;
}